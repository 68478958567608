export const IS_TOUCH = "ontouchstart" in document.documentElement;

export const chunk = <T,>(items: T[], chunksizes: number[]): T[][] => {
  const chunkArray = [];
  let cc = 0,
    i = 0;
  while (i < items.length) {
    const csize = chunksizes[cc];
    chunkArray.push(items.slice(i, i + csize));
    cc = (cc + 1) % chunksizes.length;
    i += csize;
  }
  return chunkArray;
};

export const secondsToTime = (e: number) => {
  var m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, "0");

  return m + ":" + s;
};

export const generateAudio = (src: string) => {
  if (IS_TOUCH) {
    return {
      play: () => undefined,
    };
  }

  const sound = new Audio(src);
  return {
    play: () => {
      sound.pause();
      sound.currentTime = 0;
      sound.play();
    },
  };
};
