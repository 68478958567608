import React, { ReactNode } from "react";

export const Hole: React.FC<{
  children: ReactNode;
}> = ({ children }) => (
  <svg viewBox="0 0 310 310">
    <g className="Base 1">
      <g className="base-1">
        <g className="hole">
          <path
            d="m246 287.712 9.526-8.66 21.65-1.29 6.918 5.62v4.33l-17.877 3.05-20.217-3.05Z"
            className="Vector 6"
          />
          <path
            d="m29.474 239.5 5.196 5.691-.866 6.433-10.038-1.732L22 245.191l1.766-2.969 5.708-2.722Z"
            className="Vector 4"
          />
          <ellipse cx="155" cy="258" className="Ellipse 2" rx="120" ry="16.5" />
          <path
            d="M58 214.676s2.887 15.01 4.33 30.309v4.33h32.907l5.196-26.845-11.258 19.051 6.063-26.845-8.66 15.587v-31.175l-8.66 31.175-9.526-46.763v46.763C62.042 216.408 58 214.676 58 214.676Z"
            className="Vector 1"
          />
        </g>
        <mask
          id="a"
          width="240"
          height="273"
          x="35"
          y="2"
          className="a"
          maskUnits="userSpaceOnUse"
          style={{ maskType: "alpha" }}
        >
          <path
            d="M275 2H35v256.5h.054c1.922 8.881 54.888 16 119.946 16 65.058 0 118.024-7.119 119.946-16H275V2Z"
            className="mask"
          />
        </mask>
        <g mask="url(#a)">
          {children}
          <path
            d="M255.629 257.418s-8.082 13.856-10.391 19.917l-20.784-1.731-14.722-13.856 16.454 6.928C215.794 255.686 208 249.624 208 249.624s21.65 12.99 24.248 17.32c-3.176-10.104-6.062-29.444-6.062-29.444s8.66 21.072 11.258 31.176l5.196-28.578v30.31c5.484-4.908 12.989-12.99 12.989-12.99Z"
            className="grass"
          />
        </g>
      </g>
    </g>
  </svg>
);
