import { useEffect, useReducer } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { AnimatePresence } from "framer-motion";
import { reducer } from "./state/reducer";
import { initialState } from "./state/initial";
import { Countdown } from "./components/Countdown";
import { Mole } from "./components/Mole";
import { Button } from "./components/Button";
import { chunk, secondsToTime } from "./utils";
import { useRandom } from "./hooks/use-random";
import { useInterval } from "./hooks/use-interval";
import { View } from "./components/View";
import * as sounds from "./sounds";

export const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const isPortrait = useMediaQuery("(orientation: portrait)");

  const items = isPortrait ? 6 : 7;

  const chunkedItems = chunk(
    Array.from({ length: items }, (_, index) => index),
    isPortrait ? [2] : [3, 4]
  );

  const { getRandomValue: getRandomMoleIndex } = useRandom(0, items);
  const { getRandomValue: getRandomMoleType } = useRandom(0, items);
  const { getRandomValue: getRandomHitSoundIndex } = useRandom(
    0,
    sounds.hit.length
  );
  const { getRandomValue: getRandomInterval } = useRandom(
    state.minInterval,
    state.maxInterval
  );

  const toggleStatusAction = () =>
    dispatch({
      type: "toggle-status",
    });

  const restartStatusAction = () =>
    dispatch({
      type: "restart-status",
      moleIndex: getRandomMoleIndex(),
      moleType: getRandomMoleType(),
    });

  const hitAction = () =>
    dispatch({
      type: "hit",
      increment: 1,
      moleIndex: getRandomMoleIndex(),
      moleType: getRandomMoleType(),
      interval: getRandomInterval(),
    });

  const moleAction = () =>
    dispatch({
      type: "mole",
      moleIndex: getRandomMoleIndex(),
      moleType: getRandomMoleType(),
      interval: getRandomInterval(),
    });

  const timeAction = () => dispatch({ type: "time", increment: -1 });

  const { restart: restartMoleInterval, stop: stopMoleInterval } = useInterval(
    moleAction,
    state.interval
  );
  const { restart: restartTimeInterval, stop: stopTimeInterval } = useInterval(
    timeAction,
    1000
  );

  useEffect(() => {
    if (state.status === "playing") {
      restartMoleInterval();
    }
  }, [state.status, state.score, restartMoleInterval]);

  useEffect(() => {
    if (state.status === "playing") {
      restartMoleInterval();
      restartTimeInterval();
    } else {
      stopTimeInterval();
      stopMoleInterval();
    }
  }, [
    state.status,
    stopTimeInterval,
    stopMoleInterval,
    restartMoleInterval,
    restartTimeInterval,
  ]);

  useEffect(() => {
    if (state.status === "playing") {
      sounds.hit[getRandomHitSoundIndex()].play();
    } else if (state.status === "ended") {
      sounds.success.play();
      document.documentElement.style.setProperty(
        "--background-transition-duration",
        "1s"
      );
      document.documentElement.style.removeProperty("--color-primary-h");
      document.documentElement.style.removeProperty("--color-primary-s");
      document.documentElement.style.removeProperty("--color-primary-l");
    }
    return () => {
      document.documentElement.style.removeProperty(
        "--background-transition-duration"
      );
    };
  }, [state.status, state.moleIndex, getRandomHitSoundIndex]);

  useEffect(() => {
    if (state.time === 5) {
      sounds.clock.play();
      document.documentElement.style.setProperty(
        "--color-primary-h",
        "var(--color-danger-h)"
      );
      document.documentElement.style.setProperty(
        "--color-primary-s",
        "var(--color-danger-s)"
      );
      document.documentElement.style.setProperty(
        "--color-primary-l",
        "var(--color-danger-l)"
      );
    }
  }, [state.time]);

  return (
    <AnimatePresence exitBeforeEnter>
      {state.status === "started" ? (
        <View key={0}>
          <Countdown onComplete={toggleStatusAction} />
        </View>
      ) : state.status !== "ended" ? (
        <View key={1}>
          <h1>{state.score}</h1>
          <div className="frame">
            {chunkedItems.map((row, a) => (
              <div className="row" key={a}>
                {row.map((mole, b) => (
                  <Mole
                    type={state.moleType}
                    holeType={mole % 2}
                    isActive={
                      state.status === "playing" && state.moleIndex === mole
                    }
                    onMoleClick={hitAction}
                    key={b}
                  />
                ))}
              </div>
            ))}
          </div>
          <div className="interface">
            <div className="interface__left">
              <Button onClick={restartStatusAction}>Restart</Button>
            </div>
            <small>{secondsToTime(state.time)}</small>
            <div className="interface__right">
              <Button onClick={toggleStatusAction}>
                {state.status === "playing" ? "Pause" : "Play"}
              </Button>
            </div>
          </div>
        </View>
      ) : (
        <View key={2}>
          <div className="result">
            <div>{state.score} points</div>
            <h2>You're digging it!</h2>
            <Button onClick={restartStatusAction}>Restart</Button>
          </div>
        </View>
      )}
    </AnimatePresence>
  );
};
