import { motion } from "framer-motion";

export const Mole = () => (
  <svg viewBox="0 0 310 310">
    <g className="Mole 1">
      <g className="mole-1">
        <motion.g
          className="hairs"
          initial={{ y: 30 }}
          animate={{ y: 0 }}
          transition={{
            from: 30,
            to: 0,
            duration: 0.3,
            delay: 0.2,
            ease: "backOut",
          }}
        >
          <path
            stroke="#495E76"
            strokeLinecap="round"
            strokeWidth="4"
            d="M151 70c-.381-2-2.514-6.6-8-9"
            className="Vector 10"
          />
          <path
            stroke="#495E76"
            strokeLinecap="round"
            strokeWidth="4"
            d="M155.669 72c1.111-1-.445-13-6.669-17"
            className="Vector 9"
          />
          <path
            stroke="#495E76"
            strokeLinecap="round"
            strokeWidth="4"
            d="M161.653 73c-1.749-2.762-.21-20.714 7.347-29"
            className="Vector 8"
          />
        </motion.g>
        <path
          fill="#495E76"
          d="M82 144c0-40.317 32.683-73 73-73s73 32.683 73 73v166H82V144Z"
          className="body"
        />
        <motion.g
          className="ears"
          animate={{ y: 0 }}
          transition={{
            from: -4,
            to: 4,
            repeat: Infinity,
            repeatDelay: 0.2,
            repeatType: "mirror",
            duration: 0.3,
          }}
        >
          <path
            fill="#495E76"
            d="M65 160c0-6.712 6.16-11.734 12.734-10.383l2.163.445a10.145 10.145 0 0 1 0 19.876l-2.163.445C71.159 171.734 65 166.712 65 160Z"
            className="Rectangle 10"
          />
          <path
            fill="#495E76"
            d="M221 160c0-4.807 3.4-8.943 8.117-9.871l3.128-.617C238.851 148.211 245 153.267 245 160s-6.149 11.789-12.755 10.488l-3.128-.617A10.06 10.06 0 0 1 221 160Z"
            className="Rectangle 11"
          />
        </motion.g>
        <g className="mouth">
          <path
            fill="#FFF0E3"
            d="M135 162c0-10.77 7.626-23 19.5-23 11.874 0 19.5 12.23 19.5 23s-12.5 17.5-19.5 17.5S135 172.77 135 162Z"
            className="Ellipse 5"
          />
          <ellipse
            cx="154.5"
            cy="148.5"
            fill="#161F30"
            className="Ellipse 6"
            rx="7.5"
            ry="4.5"
          />
        </g>
        <g className="eyes">
          <rect
            width="7.651"
            height="13.116"
            x="136"
            y="128"
            fill="#161F30"
            className="Rectangle 2"
            rx="3.826"
          />
          <rect
            width="7.651"
            height="13.116"
            x="167"
            y="128"
            fill="#161F30"
            className="Rectangle 3"
            rx="3.826"
          />
        </g>
      </g>
    </g>
  </svg>
);
