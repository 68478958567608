import { motion } from "framer-motion";

export const Mole = () => (
  <svg viewBox="0 0 310 310">
    <g className="Mole 7">
      <g className="mole-7">
        <path
          fill="#495E76"
          d="M82 144c0-40.317 32.683-73 73-73s73 32.683 73 73v166H82V144Z"
          className="body"
        />
        <g className="mouth">
          <path
            fill="#FFF0E3"
            d="M130 171c0-10.77 12.626-23 24.5-23s24.5 12.23 24.5 23-15.5 11.5-24.5 11.5-24.5-.73-24.5-11.5Z"
            className="Ellipse 7"
          />
          <motion.g
            initial={{ y: 10 }}
            animate={{ y: 0 }}
            transition={{
              from: -4,
              to: 0,
              repeat: Infinity,
              repeatDelay: 0.1,
              repeatType: "mirror",
              duration: 0.3,
            }}
          >
            <rect
              width="7"
              height="7"
              x="146"
              y="182"
              fill="#fff"
              className="Rectangle 12"
              rx="1"
            />
            <rect
              width="7"
              height="7"
              x="156"
              y="182"
              fill="#fff"
              className="Rectangle 13"
              rx="1"
            />
          </motion.g>
          <ellipse
            cx="154.5"
            cy="158.5"
            fill="#161F30"
            className="Ellipse 8"
            rx="5.5"
            ry="3.5"
          />
        </g>
        <motion.g
          className="glasses"
          initial={{ y: 10 }}
          animate={{ y: [-10, 5, -3, 0] }}
          transition={{
            duration: 0.6,
            delay: 0.2,
          }}
        >
          <circle
            cx="180.458"
            cy="145.5"
            r="20.5"
            fill="#fff"
            fillOpacity=".07"
            stroke="#161F30"
            strokeWidth="2"
            className="Ellipse 10"
          />
          <circle
            cx="128.5"
            cy="145.5"
            r="20.5"
            fill="#fff"
            fillOpacity=".07"
            stroke="#161F30"
            strokeWidth="2"
            className="Ellipse 11"
          />
          <rect
            width="4"
            height="4"
            x="136"
            y="144"
            fill="#161F30"
            className="Rectangle 2"
            rx="2"
          />
          <rect
            width="4"
            height="4"
            x="169"
            y="144"
            fill="#161F30"
            className="Rectangle 3"
            rx="2"
          />
          <path
            stroke="#161F30"
            strokeWidth="2"
            d="M149 148h12"
            className="Line 31"
          />
        </motion.g>
      </g>
    </g>
  </svg>
);
