import { motion } from "framer-motion";

export const Mole = () => (
  <svg viewBox="0 0 310 310">
    <g className="Mole 2">
      <g className="mole-5">
        <path
          fill="#495E76"
          d="M82 144c0-40.317 32.683-73 73-73s73 32.683 73 73v166H82V144Z"
          className="body"
        />
        <g className="eyes">
          <rect
            width="8"
            height="8"
            x="135"
            y="135"
            fill="#161F30"
            className="Rectangle 2"
            rx="4"
          />
          <rect
            width="8"
            height="8"
            x="166"
            y="135"
            fill="#161F30"
            className="Rectangle 3"
            rx="4"
          />
        </g>
        <g className="pomini">
          <circle
            cx="192.5"
            cy="150.5"
            r="7.5"
            fill="#EBDAC1"
            className="Ellipse 10"
          />
          <circle
            cx="117.5"
            cy="150.5"
            r="7.5"
            fill="#EBDAC1"
            className="Ellipse 11"
          />
        </g>
        <motion.g
          className="mouth"
          animate={{ scale: 1 }}
          transition={{
            from: 0.8,
            to: 1,
            repeat: Infinity,
            repeatDelay: 0.2,
            repeatType: "mirror",
            duration: 0.3,
          }}
        >
          <path
            fill="#EBDAC1"
            d="M133 166c0-10.77 9.626-23 21.5-23s21.5 12.23 21.5 23-12.5 11.5-21.5 11.5-21.5-.73-21.5-11.5Z"
            className="Ellipse 7"
          />
          <ellipse
            cx="154.5"
            cy="153.5"
            fill="#161F30"
            className="Ellipse 8"
            rx="5.5"
            ry="3.5"
          />
        </motion.g>
      </g>
    </g>
  </svg>
);
