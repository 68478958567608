import { generateAudio } from "../utils";

export const hit = [
  generateAudio(require("./hit/Ow1.wav")),
  generateAudio(require("./hit/Ow2.wav")),
  generateAudio(require("./hit/Ow3.wav")),
  generateAudio(require("./hit/Ooff1.wav")),
  generateAudio(require("./hit/Ooff2.wav")),
  generateAudio(require("./hit/Ooff3.wav")),
  generateAudio(require("./hit/Hello.wav")),
  generateAudio(require("./hit/jump1.wav")),
  generateAudio(require("./hit/jump2.wav")),
  generateAudio(require("./hit/oops.wav")),
];

export const success = generateAudio(require("./success/Bells11.mp3"));

export const ui = generateAudio(require("./ui/UI_Quirky8.mp3"));

export const countdown = [
  generateAudio(require("./countdown/UI_Quirky23.mp3")),
  generateAudio(require("./countdown/UI-Quirky_36.mp3")),
];

export const clock = generateAudio(
  require("./clock/zapsplat_household_clock_wrist_watch_ticking_001_73988.mp3")
);
