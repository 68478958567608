import React, { useCallback, useEffect, useState } from "react";
import { useInterval } from "../hooks/use-interval";
import { Button } from "./Button";
import * as sounds from "../sounds";

export const Countdown: React.FC<{ onComplete: () => void }> = ({
  onComplete,
}) => {
  const [hasUserClicked, setHasUserClicked] = useState(false);
  const [count, setCount] = useState(4);

  const decrement = useCallback(() => {
    setCount(Math.max(count - 1, 0));
  }, [count]);

  const onClick = () => {
    setHasUserClicked(true);
    restart();
  };

  const { stop, restart } = useInterval(decrement, 1000);

  useEffect(() => {
    if (hasUserClicked) {
      if (count > 1) {
        sounds.countdown[0].play();
      } else if (count === 1) {
        sounds.countdown[1].play();
      } else {
        onComplete();
      }
    }
  }, [count, hasUserClicked, onComplete]);

  useEffect(() => {
    stop();
  }, [stop]);

  return !hasUserClicked ? (
    <Button onClick={onClick}>Click to start!</Button>
  ) : (
    <h1>{["Go!", "Go!", 1, 2, 3][count]}</h1>
  );
};
