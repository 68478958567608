import { useRef, useEffect, useCallback } from "react";

export const useInterval = (callback: any, interval: number) => {
  const timeout = useRef<number>();
  const savedInterval = useRef<number>();
  const savedCallback = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    savedInterval.current = interval;
  }, [interval]);

  useEffect(() => {
    timeout.current = window.setInterval(
      () => savedCallback.current(),
      interval
    );

    return () => {
      clearInterval(timeout.current);
    };
  }, [interval]);

  const stop = useCallback(() => {
    clearInterval(timeout.current);
  }, []);

  const restart = useCallback(() => {
    clearInterval(timeout.current);
    timeout.current = window.setInterval(
      () => savedCallback.current(),
      savedInterval.current
    );
  }, []);

  return {
    stop,
    restart,
  };
};
