export type State = {
  interval: number;
  maxInterval: number;
  minInterval: number;
  moleIndex: number;
  moleType: number;
  score: number;
  status: "started" | "playing" | "paused" | "ended";
  time: number;
};

export const initialState: State = {
  interval: 1100,
  maxInterval: 1100,
  minInterval: 700,
  moleIndex: 0,
  moleType: 0,
  score: 0,
  status: "started",
  time: 20,
};
