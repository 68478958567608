import { useRef, useCallback } from "react";

export const useRandom = (min: number, max: number) => {
  const previous = useRef<number>();

  const getRandomValue: () => number = useCallback(() => {
    const difference = max - min;
    let value = Math.floor(Math.random() * difference);
    value = value + min;

    if (value === previous.current) {
      return getRandomValue();
    }

    previous.current = value;
    return value;
  }, [min, max]);

  return { getRandomValue };
};
