import { Reducer } from "react";
import { initialState, State } from "./initial";

export type ToggleStatusAction = {
  type: "toggle-status";
};

export type RestartStatusAction = {
  type: "restart-status";
  moleIndex: number;
  moleType: number;
};

export type HitAction = {
  type: "hit";
  increment: number;
  moleIndex: number;
  moleType: number;
  interval: number;
};

export type MoleAction = {
  type: "mole";
  moleIndex: number;
  moleType: number;
  interval: number;
};

export type TimeAction = {
  type: "time";
  increment: number;
};

type Actions =
  | HitAction
  | TimeAction
  | MoleAction
  | ToggleStatusAction
  | RestartStatusAction;

export const reducer: Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case "hit":
      const score = state.score + action.increment;
      const minInterval = Math.max(state.minInterval - 20, 300);
      const maxInterval = Math.max(state.maxInterval - 20, 500);

      return {
        ...state,
        score,
        moleIndex: action.moleIndex,
        moleType: action.moleType,
        interval: action.interval,
        minInterval,
        maxInterval,
      };
    case "time": {
      const time = Math.max(state.time + action.increment, 0);
      return {
        ...state,
        time,
        status: time === 0 ? "ended" : state.status,
      };
    }
    case "mole": {
      return {
        ...state,
        moleIndex: action.moleIndex,
        moleType: action.moleType,
        interval: action.interval,
      };
    }
    case "restart-status": {
      return {
        ...initialState,
        moleIndex: action.moleIndex,
        moleType: action.moleType,
        status: "playing",
      };
    }
    case "toggle-status": {
      return {
        ...state,
        status: state.status !== "playing" ? "playing" : "paused",
      };
    }
    default:
      throw new Error(`Invalid action type`);
  }
};
