import { motion } from "framer-motion";

export const Mole = () => (
  <svg viewBox="0 0 310 310">
    <g className="Mole 3">
      <g className="mole-2">
        <motion.g
          className="hairs"
          initial={{ y: 30 }}
          animate={{ y: 0 }}
          transition={{
            from: 30,
            to: 0,
            duration: 0.3,
            delay: 0.2,
            ease: "backOut",
          }}
        >
          <path
            stroke="#495E76"
            strokeLinecap="round"
            strokeWidth="4"
            d="M151 69c-.381-2-2.514-6.6-8-9"
            className="Vector 10"
          />
          <path
            stroke="#495E76"
            strokeLinecap="round"
            strokeWidth="4"
            d="M155.669 71c1.111-1-.445-13-6.669-17"
            className="Vector 9"
          />
          <path
            stroke="#495E76"
            strokeLinecap="round"
            strokeWidth="4"
            d="M161.653 72c-1.749-2.762-.21-20.714 7.347-29"
            className="Vector 8"
          />
        </motion.g>
        <path
          fill="#495E76"
          d="M82 144c0-40.317 32.683-73 73-73s73 32.683 73 73v166H82V144Z"
          className="body"
        />
        <motion.g
          className="mouth"
          animate={{ scale: 1 }}
          transition={{
            from: 0.8,
            to: 1,
            repeat: Infinity,
            repeatDelay: 0.2,
            repeatType: "mirror",
            duration: 0.3,
          }}
        >
          <path
            fill="#FFF0E3"
            d="M131 165.262c0-11.36 10.01-24.262 23.79-24.262S178 153.902 178 165.262c0 11.361-15.086 6.857-23.21 6.857-8.123 0-23.79 4.504-23.79-6.857Z"
            className="Ellipse 5"
          />
          <circle
            cx="142"
            cy="157"
            r="1"
            fill="#495E76"
            className="Ellipse 15"
          />
          <circle
            cx="167"
            cy="157"
            r="1"
            fill="#495E76"
            className="Ellipse 18"
          />
          <circle
            cx="145"
            cy="164"
            r="1"
            fill="#495E76"
            className="Ellipse 16"
          />
          <circle
            cx="170"
            cy="164"
            r="1"
            fill="#495E76"
            className="Ellipse 19"
          />
          <circle
            cx="138"
            cy="164"
            r="1"
            fill="#495E76"
            className="Ellipse 17"
          />
          <circle
            cx="163"
            cy="164"
            r="1"
            fill="#495E76"
            className="Ellipse 20"
          />
          <ellipse
            cx="154.5"
            cy="147.5"
            fill="#161F30"
            className="Ellipse 6"
            rx="7.5"
            ry="4.5"
          />
        </motion.g>
        <g className="eyes">
          <circle
            cx="133.5"
            cy="129.5"
            r="8.75"
            fill="#161F30"
            stroke="#161F30"
            strokeWidth="1.5"
            className="Ellipse 13"
          />
          <circle
            cx="174.5"
            cy="129.5"
            r="8.75"
            fill="#161F30"
            stroke="#161F30"
            strokeWidth="1.5"
            className="Ellipse 14"
          />
          <rect
            width="5"
            height="5"
            x="135"
            y="129"
            fill="#FFF0E3"
            className="Rectangle 2"
            rx="2.5"
          />
          <rect
            width="5"
            height="5"
            x="169"
            y="129"
            fill="#FFF0E3"
            className="Rectangle 3"
            rx="2.5"
          />
        </g>
      </g>
    </g>
  </svg>
);
