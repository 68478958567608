import React, { ReactNode } from "react";

export const Hole: React.FC<{
  children: ReactNode;
}> = ({ children }) => (
  <svg viewBox="0 0 310 310">
    <g className="Base 2">
      <g className="base-1">
        <g className="hole">
          <path
            d="m261.474 235 5.196 5.691-.866 6.433-10.038-1.732-1.766-4.701 1.766-2.969 5.708-2.722Z"
            className="Vector 4"
          />
          <ellipse cx="155" cy="258" className="Ellipse 2" rx="120" ry="16.5" />
          <path
            d="M207 217.176s2.887 15.01 4.33 30.309v4.33h32.907l5.196-26.845-11.257 19.051 6.061-26.845-8.659 15.587v-31.175l-8.66 31.175L217.392 186v46.763C211.041 218.908 207 217.176 207 217.176Z"
            className="Vector 1"
          />
        </g>
        <mask
          id="a"
          width="240"
          height="273"
          x="35"
          y="2"
          className="a"
          maskUnits="userSpaceOnUse"
          style={{ maskType: "alpha" }}
        >
          <path
            d="M275 2H35v256.5h.054c1.922 8.881 54.888 16 119.946 16 65.058 0 118.024-7.119 119.946-16H275V2Z"
            className="mask"
          />
        </mask>
        <g mask="url(#a)">
          {children}
          <path
            d="m74 274.212 9.526-8.66 21.65-1.29 6.918 5.62v4.33l-17.877 3.05L74 274.212Z"
            className="grass"
          />
        </g>
      </g>
    </g>
  </svg>
);
