import { motion } from "framer-motion";
import React, { ReactNode } from "react";

export const View: React.FC<{ children: ReactNode }> = ({ children }) => (
  <motion.div
    className="view"
    initial="hidden"
    animate="show"
    variants={{
      show: {
        pointerEvents: "unset",
        opacity: 1,
      },
      hidden: {
        pointerEvents: "none",
        opacity: 0,
      },
    }}
    transition={{ duration: 0.2 }}
    exit="hidden"
  >
    {children}
  </motion.div>
);
