import { IS_TOUCH } from "../utils";

export const useClickHandler = (fn: any) => {
  return IS_TOUCH
    ? {
        onTouchStart: fn,
      }
    : {
        onMouseDown: fn,
      };
};
