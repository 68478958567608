import { motion } from "framer-motion";

export const Mole = () => (
  <svg viewBox="0 0 310 310">
    <g className="Mole 6">
      <g className="mole-6">
        <path
          fill="#495E76"
          d="M82 144c0-40.317 32.683-73 73-73s73 32.683 73 73v166H82V144Z"
          className="body"
        />
        <g className="mouth">
          <path
            fill="#FFF0E3"
            d="M141 173.667c0-10.614 4.149-22.667 13.5-22.667 9.351 0 13.5 12.053 13.5 22.667C168 184.28 159.314 187 154.5 187s-13.5-2.72-13.5-13.333Z"
            className="Ellipse 7"
          />
          <ellipse
            cx="154.5"
            cy="161.5"
            fill="#161F30"
            className="Ellipse 8"
            rx="5.5"
            ry="3.5"
          />
        </g>
        <motion.g
          className="eyebrows"
          initial={{ y: 20 }}
          animate={{ y: [-20, 10, -5, 0] }}
          transition={{
            duration: 0.6,
            delay: 0.2,
          }}
        >
          <path
            fill="#161F30"
            d="M149 129.423c0-2.416-2.611-3.931-4.709-2.732l-.499.285a36.292 36.292 0 0 1-26.094 3.869 3.025 3.025 0 0 0-3.698 2.949v3.232a4 4 0 0 0 2.901 3.846l1.412.404a48.012 48.012 0 0 0 26.374 0l1.412-.404a4 4 0 0 0 2.901-3.846v-7.603Z"
            className="Rectangle 7"
          />
          <path
            fill="#161F30"
            d="M194 133.794a3.025 3.025 0 0 0-3.698-2.949 36.292 36.292 0 0 1-26.094-3.869l-.499-.285c-2.098-1.199-4.709.316-4.709 2.732v7.603a4 4 0 0 0 2.901 3.846l1.412.404a48.012 48.012 0 0 0 26.374 0l1.412-.404a4 4 0 0 0 2.901-3.846v-3.232Z"
            className="Rectangle 8"
          />
        </motion.g>
        <g className="eyes">
          <rect
            width="8"
            height="8"
            x="135"
            y="140"
            fill="#161F30"
            className="Rectangle 2"
            rx="4"
          />
          <rect
            width="8"
            height="8"
            x="166"
            y="140"
            fill="#161F30"
            className="Rectangle 3"
            rx="4"
          />
        </g>
      </g>
    </g>
  </svg>
);
