import React from "react";
import { useClickHandler } from "../hooks/use-click-handler";
import * as sounds from "../sounds";

export const Button: React.FC<{ onClick: () => void; children: string }> = ({
  onClick,
  children,
}) => {
  const clickHandler = useClickHandler(() => {
    sounds.ui.play();
    onClick();
  });

  return (
    <button className="btn" {...clickHandler}>
      {children}
    </button>
  );
};
