import React from "react";
import { motion } from "framer-motion";

import { Mole as Mole0 } from "./moles/0";
import { Mole as Mole1 } from "./moles/1";
import { Mole as Mole2 } from "./moles/2";
import { Mole as Mole3 } from "./moles/3";
import { Mole as Mole4 } from "./moles/4";
import { Mole as Mole5 } from "./moles/5";
import { Mole as Mole6 } from "./moles/6";
import { Hole as Hole0 } from "./holes/0";
import { Hole as Hole1 } from "./holes/1";
import { AnimatePresence } from "framer-motion";
import { useClickHandler } from "../hooks/use-click-handler";

const Moles = [Mole0, Mole1, Mole2, Mole3, Mole4, Mole5, Mole6];
const Holes = [Hole0, Hole1];

export const Mole: React.FC<{
  isActive: boolean;
  onMoleClick: React.EventHandler<React.SyntheticEvent>;
  type: number;
  holeType: number;
}> = ({ isActive, onMoleClick, type, holeType }) => {
  const MoleComponent = Moles[type];
  const HoleComponent = Holes[holeType];
  const clickHandler = useClickHandler(onMoleClick);

  return (
    <div className="mole">
      <HoleComponent>
        <AnimatePresence>
          {isActive && (
            <>
              <motion.g
                className="pointer"
                initial="hidden"
                animate="show"
                variants={{
                  show: {
                    y: "7%",
                    pointerEvents: "unset",
                    transition: {
                      ease: "backOut",
                      duration: 0.5,
                    },
                  },
                  hidden: {
                    y: "100%",
                    pointerEvents: "none",
                    transition: { ease: "backIn", duration: 0.4 },
                  },
                }}
                whileTap={{
                  scale: 0.8,
                }}
                exit="hidden"
                {...clickHandler}
              >
                <MoleComponent />
                <motion.rect
                  className="shadow"
                  initial="hidden"
                  animate="show"
                  variants={{
                    show: {
                      opacity: 1,
                    },
                    hidden: {
                      opacity: 0,
                      transition: {
                        delay: 0.1,
                        duration: 0.2,
                      },
                    },
                  }}
                  exit="hidden"
                  x="82"
                  y="242"
                  width="146"
                  height="56"
                  fill="black"
                  fillOpacity={0.2}
                />
              </motion.g>
            </>
          )}
        </AnimatePresence>
      </HoleComponent>
    </div>
  );
};
