import { motion } from "framer-motion";

export const Mole = () => (
  <svg viewBox="0 0 310 310">
    <g className="Mole 4">
      <g className="mole-3">
        <path
          fill="#495E76"
          d="M82 144c0-40.317 32.683-73 73-73s73 32.683 73 73v166H82V144Z"
          className="body"
        />
        <motion.g
          className="hairs"
          initial={{ rotateZ: 30 }}
          animate={{ rotateZ: [-30, 20, -10, 0] }}
          transition={{
            duration: 0.6,
            delay: 0.2,
          }}
        >
          <path
            fill="#161F30"
            d="M184.938 90.15a32.398 32.398 0 0 0 1.012-8.055C185.95 64.369 171.691 50 154.103 50S120.1 64.19 125.05 82.095c4.95 17.905 17.792 8.67 21.579 22.667.391 1.447.687 2.51.871 3.738 1.648 11.02-10.768 28.5-10.768 28.5 27.428-12.191 42.501-24.603 48.206-46.85Z"
            className="Vector"
          />
          <path
            fill="#161F30"
            d="M129.091 127.583c12.622-18.863 8.765-25.5 4.207-34.583l12.181 5.986 4.65 11.83c-11.922 9.432-22.205 18.511-21.038 16.767Z"
            className="Vector"
          />
        </motion.g>
        <g className="eyes">
          <rect
            width="8"
            height="8"
            x="136"
            y="135"
            fill="#161F30"
            className="Rectangle 2"
            rx="4"
          />
          <rect
            width="8"
            height="8"
            x="167"
            y="135"
            fill="#161F30"
            className="Rectangle 3"
            rx="4"
          />
        </g>
        <g className="mouth">
          <path
            fill="#FFF0E3"
            d="M133 169c0-10.77 9.626-23 21.5-23s21.5 12.23 21.5 23-12.5 11.5-21.5 11.5-21.5-.73-21.5-11.5Z"
            className="Ellipse 7"
          />
          <ellipse
            cx="154.5"
            cy="156.5"
            fill="#161F30"
            className="Ellipse 8"
            rx="5.5"
            ry="3.5"
          />
        </g>
      </g>
    </g>
  </svg>
);
