import { motion } from "framer-motion";

export const Mole = () => (
  <svg viewBox="0 0 310 310">
    <g className="Mole 5">
      <g className="mole-4">
        <path
          fill="#495E76"
          d="M82 144c0-40.317 32.683-73 73-73s73 32.683 73 73v166H82V144Z"
          className="body"
        />
        <g className="freckles">
          <path stroke="#1F2C44" d="M127.5 189v5.714" className="Line 18" />
          <path stroke="#1F2C44" d="M87.5 171.143v5.714" className="Line 22" />
          <path stroke="#1F2C44" d="M141.5 189v5.714" className="Line 26" />
          <path stroke="#1F2C44" d="M169.5 189v5.714" className="Line 30" />
          <path stroke="#1F2C44" d="M92.5 162v5.714" className="Line 19" />
          <path stroke="#1F2C44" d="M100.5 171.143v5.714" className="Line 23" />
          <path stroke="#1F2C44" d="M92.5 180.286V186" className="Line 27" />
          <path stroke="#1F2C44" d="M107.5 162v5.714" className="Line 20" />
          <path stroke="#1F2C44" d="M113.5 171.143v5.714" className="Line 24" />
          <path stroke="#1F2C44" d="M107.5 180.286V186" className="Line 28" />
          <path stroke="#1F2C44" d="M122.5 162v5.714" className="Line 21" />
          <path stroke="#1F2C44" d="M126.5 171.143v5.714" className="Line 25" />
          <path stroke="#1F2C44" d="M122.5 180.286V186" className="Line 29" />
        </g>
        <g className="Group 16">
          <path stroke="#1F2C44" d="M182.5 195v-5.714" className="Line 18" />
          <path
            stroke="#1F2C44"
            d="M222.5 176.857v-5.714"
            className="Line 22"
          />
          <path stroke="#1F2C44" d="M154.5 194.714V189" className="Line 26" />
          <path stroke="#1F2C44" d="M218.5 186v-5.714" className="Line 19" />
          <path
            stroke="#1F2C44"
            d="M209.5 176.857v-5.714"
            className="Line 23"
          />
          <path stroke="#1F2C44" d="M218.5 167.714V162" className="Line 27" />
          <path stroke="#1F2C44" d="M203.5 186v-5.714" className="Line 20" />
          <path
            stroke="#1F2C44"
            d="M196.5 176.857v-5.714"
            className="Line 24"
          />
          <path stroke="#1F2C44" d="M203.5 167.714V162" className="Line 28" />
          <path stroke="#1F2C44" d="M188.5 186v-5.714" className="Line 21" />
          <path
            stroke="#1F2C44"
            d="M183.5 176.857v-5.714"
            className="Line 25"
          />
          <path stroke="#1F2C44" d="M188.5 167.714V162" className="Line 29" />
        </g>
        <motion.g
          className="hat"
          animate={{ y: [-20, 10, -10, 0] }}
          transition={{
            duration: 0.6,
            delay: 0.2,
          }}
        >
          <path
            fill="#161F30"
            d="M84 134c0-37.555 30.445-68 68-68h6c37.555 0 68 30.445 68 68H84Z"
            className="Rectangle 2"
          />
          <g className="Group 13">
            <rect
              width="152"
              height="30"
              x="79"
              y="104"
              fill="#74879C"
              className="Rectangle 9"
              rx="9"
            />
            <g className="Group 14">
              <path stroke="#161F30" d="M89.5 107v23" className="Line 3" />
              <path stroke="#161F30" d="M109.5 107v23" className="Line 6" />
              <path stroke="#161F30" d="M129.5 107v23" className="Line 8" />
              <path stroke="#161F30" d="M149.5 107v23" className="Line 10" />
              <path stroke="#161F30" d="M169.5 107v23" className="Line 12" />
              <path stroke="#161F30" d="M189.5 107v23" className="Line 14" />
              <path stroke="#161F30" d="M209.5 107v23" className="Line 16" />
              <path stroke="#161F30" d="M99.5 107v23" className="Line 5" />
              <path stroke="#161F30" d="M119.5 107v23" className="Line 7" />
              <path stroke="#161F30" d="M139.5 107v23" className="Line 9" />
              <path stroke="#161F30" d="M159.5 107v23" className="Line 11" />
              <path stroke="#161F30" d="M179.5 107v23" className="Line 13" />
              <path stroke="#161F30" d="M199.5 107v23" className="Line 15" />
              <path stroke="#161F30" d="M219.5 107v23" className="Line 17" />
            </g>
          </g>
        </motion.g>
        <g className="eyes">
          <rect
            width="8"
            height="8"
            x="133"
            y="137"
            fill="#161F30"
            className="Rectangle 2"
            rx="4"
          />
          <rect
            width="8"
            height="8"
            x="167"
            y="137"
            fill="#161F30"
            className="Rectangle 3"
            rx="4"
          />
        </g>
        <g className="mouth">
          <path
            fill="#FFF0E3"
            d="M133 171c0-10.77 9.626-23 21.5-23s21.5 12.23 21.5 23-12.5 11.5-21.5 11.5-21.5-.73-21.5-11.5Z"
            className="Ellipse 7"
          />
          <ellipse
            cx="154.5"
            cy="158.5"
            fill="#161F30"
            className="Ellipse 8"
            rx="5.5"
            ry="3.5"
          />
        </g>
      </g>
    </g>
  </svg>
);
